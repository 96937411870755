import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65f677b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "text-muted small mb-3" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "d-flex align-items-center" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["src"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "d-flex align-items-center" }
const _hoisted_13 = ["src", "alt"]
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { key: 4 }
const _hoisted_17 = { key: 5 }
const _hoisted_18 = { key: 6 }
const _hoisted_19 = { key: 7 }
const _hoisted_20 = { key: 8 }
const _hoisted_21 = { key: 9 }
const _hoisted_22 = ["src"]
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { class: "d-flex align-items-center" }
const _hoisted_25 = ["src", "alt"]
const _hoisted_26 = { key: 10 }
const _hoisted_27 = ["src"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { class: "d-flex align-items-center" }
const _hoisted_30 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_match_score_result_badge = _resolveComponent("match-score-result-badge")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_knoctout_score = _resolveComponent("knoctout-score")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_ctx.lengthMatch)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `section-${section?.id}`
          }, [
            _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.store.state.currentLanguage === "th"
            ? section?.competition_section_name_th
            : section?.competition_section_name_en), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.store.state.currentLanguage === "th"
            ? section?.competition_section_description_th
            : section?.competition_section_description_en), 1),
            (
          section?.section_type === 'GROUP' &&
          section?.competition_section_round_name === 'GROUP_STAGE'
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sectionGroup, (groupStage) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `groupStage-${groupStage?.id}`
                    }, [
                      _createVNode(_component_a_card, {
                        class: "p-0 mb-3",
                        title: 
              _ctx.store.state.currentLanguage === 'th'
                ? groupStage?.competition_group_stage_name
                : groupStage?.competition_group_stage_name_en
            ,
                        bodyStyle: { padding: '1px' },
                        style: { borderRadius: '20px' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_table, {
                            columns: groupStage?.columns[_ctx.store.state.currentLanguage],
                            "data-source": groupStage?.dataTable,
                            pagination: false,
                            "row-key": (record) => record.team_id
                          }, _createSlots({
                            team: _withCtx(({ text: team_name, record }) => [
                              _createElementVNode("div", _hoisted_7, [
                                (record?.team_image)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: record?.team_image,
                                      width: "30",
                                      class: "me-2",
                                      alt: team_name.charAt(0)
                                    }, null, 8, _hoisted_8))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", null, _toDisplayString(_ctx.store.state.currentLanguage === "th"
                      ? team_name
                      : record.team_name_en), 1)
                              ])
                            ]),
                            _: 2
                          }, [
                            _renderList(groupStage?.teamColumns, (teamCol) => {
                              return {
                                name: `teamHeader-${teamCol?.teamId}`,
                                fn: _withCtx(() => [
                                  _createElementVNode("p", null, [
                                    (teamCol?.image)
                                      ? (_openBlock(), _createElementBlock("img", {
                                          key: 0,
                                          src: teamCol?.image,
                                          width: "30"
                                        }, null, 8, _hoisted_5))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(teamCol?.name), 1))
                                  ])
                                ])
                              }
                            }),
                            _renderList(groupStage?.teamColumns, (teamCol) => {
                              return {
                                name: `teamResult-${teamCol?.teamId}`,
                                fn: _withCtx(({ text }) => [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_match_score_result_badge, {
                                      matchResult: text.find((ele) => ele.vs == teamCol?.teamId),
                                      competitionId: Number(_ctx.competitionId),
                                      subCompetitionId: Number(_ctx.subCompetitionId),
                                      competitionSectionId: 
                      Number(groupStage?.competition_section_id)
                    
                                    }, null, 8, ["matchResult", "competitionId", "subCompetitionId", "competitionSectionId"])
                                  ])
                                ])
                              }
                            })
                          ]), 1032, ["columns", "data-source", "row-key"])
                        ]),
                        _: 2
                      }, 1032, ["title"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (
        section?.section_type === 'GROUP' &&
        section?.competition_section_round_name === 'NINTH_PLACE'
      )
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ninethPlace, (groupStage) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `groupStage-${groupStage?.id}`
                    }, [
                      _createVNode(_component_a_card, {
                        class: "p-0 mb-3",
                        title: 
            _ctx.store.state.currentLanguage === 'th'
              ? groupStage?.competition_group_stage_name
              : groupStage?.competition_group_stage_name_en
          ,
                        bodyStyle: { padding: '1px' },
                        style: { borderRadius: '20px' }
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_table, {
                            columns: groupStage?.columns[_ctx.store.state.currentLanguage],
                            "data-source": groupStage?.dataTable,
                            pagination: false,
                            "row-key": (record) => record.team_id
                          }, _createSlots({
                            team: _withCtx(({ text: team_name, record }) => [
                              _createElementVNode("div", _hoisted_12, [
                                (record?.team_image)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: record?.team_image,
                                      width: "30",
                                      class: "me-2",
                                      alt: team_name.charAt(0)
                                    }, null, 8, _hoisted_13))
                                  : _createCommentVNode("", true),
                                _createElementVNode("span", null, _toDisplayString(_ctx.store.state.currentLanguage === "th"
                    ? team_name
                    : record.team_name_en), 1)
                              ])
                            ]),
                            _: 2
                          }, [
                            _renderList(groupStage?.teamColumns, (teamCol) => {
                              return {
                                name: `teamHeader-${teamCol?.teamId}`,
                                fn: _withCtx(() => [
                                  _createElementVNode("p", null, [
                                    (teamCol?.image)
                                      ? (_openBlock(), _createElementBlock("img", {
                                          key: 0,
                                          src: teamCol?.image,
                                          width: "30"
                                        }, null, 8, _hoisted_10))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(teamCol?.name), 1))
                                  ])
                                ])
                              }
                            }),
                            _renderList(groupStage?.teamColumns, (teamCol) => {
                              return {
                                name: `teamResult-${teamCol?.teamId}`,
                                fn: _withCtx(({ text }) => [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_match_score_result_badge, {
                                      matchResult: text.find((ele) => ele.vs == teamCol?.teamId),
                                      competitionId: Number(_ctx.competitionId),
                                      subCompetitionId: Number(_ctx.subCompetitionId),
                                      competitionSectionId: 
                    Number(groupStage?.competition_section_id)
                  
                                    }, null, 8, ["matchResult", "competitionId", "subCompetitionId", "competitionSectionId"])
                                  ])
                                ])
                              }
                            })
                          ]), 1032, ["columns", "data-source", "row-key"])
                        ]),
                        _: 2
                      }, 1032, ["title"])
                    ]))
                  }), 128))
                ]))
              : (
          section?.section_type === 'KNOCKOUT' &&
          section?.competition_section_round_name === 'FIRST_PLACE'
        )
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_knoctout_score, {
                      data: _ctx.final,
                      competitionId: Number(_ctx.competitionId),
                      subCompetitionId: Number(_ctx.subCompetitionId),
                      competitionSectionId: _ctx.final?.competitionSectionId
                    }, null, 8, ["data", "competitionId", "subCompetitionId", "competitionSectionId"])
                  ]))
                : (
          section?.section_type === 'KNOCKOUT' &&
          section?.competition_section_round_name === 'THIRD_PLACE'
        )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_component_knoctout_score, {
                        data: _ctx.semifinal,
                        competitionId: Number(_ctx.competitionId),
                        subCompetitionId: Number(_ctx.subCompetitionId),
                        competitionSectionId: _ctx.semifinal?.competitionSectionId
                      }, null, 8, ["data", "competitionId", "subCompetitionId", "competitionSectionId"])
                    ]))
                  : (
          section?.section_type === 'KNOCKOUT' &&
          section?.competition_section_round_name === 'FIFTH_PLACE'
        )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createVNode(_component_knoctout_score, {
                          data: _ctx.thirdmatch,
                          competitionId: Number(_ctx.competitionId),
                          subCompetitionId: Number(_ctx.subCompetitionId),
                          competitionSectionId: _ctx.thirdmatch?.competitionSectionId
                        }, null, 8, ["data", "competitionId", "subCompetitionId", "competitionSectionId"])
                      ]))
                    : (
          section?.section_type === 'KNOCKOUT' &&
          section?.competition_section_round_name === 'SEVENTH_PLACE'
        )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createVNode(_component_knoctout_score, {
                            data: _ctx.thirdRound,
                            competitionId: Number(_ctx.competitionId),
                            subCompetitionId: Number(_ctx.subCompetitionId),
                            competitionSectionId: _ctx.thirdRound?.competitionSectionId
                          }, null, 8, ["data", "competitionId", "subCompetitionId", "competitionSectionId"])
                        ]))
                      : (
          section?.section_type === 'KNOCKOUT' &&
          section?.competition_section_round_name === 'WINNER_SEMI'
        )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createVNode(_component_knoctout_score, {
                              data: _ctx.secondRound,
                              competitionId: Number(_ctx.competitionId),
                              subCompetitionId: Number(_ctx.subCompetitionId),
                              competitionSectionId: _ctx.secondRound?.competitionSectionId
                            }, null, 8, ["data", "competitionId", "subCompetitionId", "competitionSectionId"])
                          ]))
                        : (
        section?.section_type === 'KNOCKOUT' &&
        section?.competition_section_round_name === 'LOSER_SEMI'
      )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                              _createVNode(_component_knoctout_score, {
                                data: _ctx.loserSemi,
                                competitionId: Number(_ctx.competitionId),
                                subCompetitionId: Number(_ctx.subCompetitionId),
                                competitionSectionId: _ctx.loserSemi?.competitionSectionId
                              }, null, 8, ["data", "competitionId", "subCompetitionId", "competitionSectionId"])
                            ]))
                          : (
      section?.section_type === 'KNOCKOUT' &&
      section?.competition_section_round_name === 'QUARTER_FINAL'
    )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createVNode(_component_knoctout_score, {
                                  data: _ctx.quarterFinal,
                                  competitionId: Number(_ctx.competitionId),
                                  subCompetitionId: Number(_ctx.subCompetitionId),
                                  competitionSectionId: _ctx.quarterFinal?.competitionSectionId
                                }, null, 8, ["data", "competitionId", "subCompetitionId", "competitionSectionId"])
                              ]))
                            : (
          section?.section_type === 'GROUP' &&
          section?.competition_section_round_name === 'SECOND_ROUND'
        )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupSecondRound, (groupStage) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: `groupStage-${groupStage?.id}`
                                    }, [
                                      _createVNode(_component_a_card, {
                                        class: "p-0 mb-3",
                                        title: 
              _ctx.store.state.currentLanguage === 'th'
                ? groupStage?.competition_group_stage_name
                : groupStage?.competition_group_stage_name_en
            ,
                                        bodyStyle: { padding: '1px' },
                                        style: { borderRadius: '20px' }
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_table, {
                                            columns: groupStage?.columns[_ctx.store.state.currentLanguage],
                                            "data-source": groupStage?.dataTable,
                                            pagination: false,
                                            "row-key": (record) => record?.team_id
                                          }, _createSlots({
                                            team: _withCtx(({ text: team_name, record }) => [
                                              _createElementVNode("div", _hoisted_24, [
                                                (record?.team_image)
                                                  ? (_openBlock(), _createElementBlock("img", {
                                                      key: 0,
                                                      src: record?.team_image,
                                                      width: "30",
                                                      class: "me-2",
                                                      alt: team_name.charAt(0)
                                                    }, null, 8, _hoisted_25))
                                                  : _createCommentVNode("", true),
                                                _createElementVNode("span", null, _toDisplayString(_ctx.store.state.currentLanguage === "th"
                      ? team_name
                      : record.team_name_en), 1)
                                              ])
                                            ]),
                                            _: 2
                                          }, [
                                            _renderList(groupStage?.teamColumns, (teamCol) => {
                                              return {
                                                name: `teamHeader-${teamCol?.teamId}`,
                                                fn: _withCtx(() => [
                                                  _createElementVNode("p", null, [
                                                    (teamCol?.image)
                                                      ? (_openBlock(), _createElementBlock("img", {
                                                          key: 0,
                                                          src: teamCol?.image,
                                                          width: "30"
                                                        }, null, 8, _hoisted_22))
                                                      : (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(teamCol?.name), 1))
                                                  ])
                                                ])
                                              }
                                            }),
                                            _renderList(groupStage?.teamColumns, (teamCol) => {
                                              return {
                                                name: `teamResult-${teamCol?.teamId}`,
                                                fn: _withCtx(({ text }) => [
                                                  _createElementVNode("div", null, [
                                                    _createVNode(_component_match_score_result_badge, {
                                                      matchResult: text.find((ele) => ele.vs == teamCol?.teamId),
                                                      competitionId: Number(_ctx.competitionId),
                                                      subCompetitionId: Number(_ctx.subCompetitionId),
                                                      competitionSectionId: 
                      Number(groupStage?.competition_section_id)
                    
                                                    }, null, 8, ["matchResult", "competitionId", "subCompetitionId", "competitionSectionId"])
                                                  ])
                                                ])
                                              }
                                            })
                                          ]), 1032, ["columns", "data-source", "row-key"])
                                        ]),
                                        _: 2
                                      }, 1032, ["title"])
                                    ]))
                                  }), 128))
                                ]))
                              : (
          section?.section_type === 'GROUP' &&
          section?.competition_section_round_name === 'THIRD_ROUND'
        )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupThirdRound, (groupStage) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: `groupStage-${groupStage?.id}`
                                      }, [
                                        _createVNode(_component_a_card, {
                                          class: "p-0 mb-3",
                                          title: 
              _ctx.store.state.currentLanguage === 'th'
                ? groupStage?.competition_group_stage_name
                : groupStage?.competition_group_stage_name_en
            ,
                                          bodyStyle: { padding: '1px' },
                                          style: { borderRadius: '20px' }
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_a_table, {
                                              columns: groupStage?.columns[_ctx.store.state.currentLanguage],
                                              "data-source": groupStage?.dataTable,
                                              pagination: false,
                                              "row-key": (record) => record?.team_id
                                            }, _createSlots({
                                              team: _withCtx(({ text: team_name, record }) => [
                                                _createElementVNode("div", _hoisted_29, [
                                                  (record?.team_image)
                                                    ? (_openBlock(), _createElementBlock("img", {
                                                        key: 0,
                                                        src: record?.team_image,
                                                        width: "30",
                                                        class: "me-2",
                                                        alt: team_name.charAt(0)
                                                      }, null, 8, _hoisted_30))
                                                    : _createCommentVNode("", true),
                                                  _createElementVNode("span", null, _toDisplayString(_ctx.store.state.currentLanguage === "th"
                      ? team_name
                      : record.team_name_en), 1)
                                                ])
                                              ]),
                                              _: 2
                                            }, [
                                              _renderList(groupStage?.teamColumns, (teamCol) => {
                                                return {
                                                  name: `teamHeader-${teamCol?.teamId}`,
                                                  fn: _withCtx(() => [
                                                    _createElementVNode("p", null, [
                                                      (teamCol?.image)
                                                        ? (_openBlock(), _createElementBlock("img", {
                                                            key: 0,
                                                            src: teamCol?.image,
                                                            width: "30"
                                                          }, null, 8, _hoisted_27))
                                                        : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(teamCol?.name), 1))
                                                    ])
                                                  ])
                                                }
                                              }),
                                              _renderList(groupStage?.teamColumns, (teamCol) => {
                                                return {
                                                  name: `teamResult-${teamCol?.teamId}`,
                                                  fn: _withCtx(({ text }) => [
                                                    _createElementVNode("div", null, [
                                                      _createVNode(_component_match_score_result_badge, {
                                                        matchResult: text.find((ele) => ele.vs == teamCol?.teamId),
                                                        competitionId: Number(_ctx.competitionId),
                                                        subCompetitionId: Number(_ctx.subCompetitionId),
                                                        competitionSectionId: 
                      Number(groupStage?.competition_section_id)
                    
                                                      }, null, 8, ["matchResult", "competitionId", "subCompetitionId", "competitionSectionId"])
                                                    ])
                                                  ])
                                                }
                                              })
                                            ]), 1032, ["columns", "data-source", "row-key"])
                                          ]),
                                          _: 2
                                        }, 1032, ["title"])
                                      ]))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createBlock(_component_a_empty, { key: 1 }))
}