
import { defineComponent, PropType, reactive, toRef, toRefs } from "vue";
import MatchScoreResultBadge from "./MatchScoreResultBadge.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  components: {
    MatchScoreResultBadge,
  },
  props: {
    data: Object,
    competitionId: {
      type: Number,
      required: true,
    },
    subCompetitionId: {
      type: Number,
      required: true,
    },
    competitionSectionId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const state = reactive({
      columns: {
        th: [
          {
            key: "1",
            title: "แข่งขันวันที่",
            dataIndex: "date",
            slots: { customRender: "date" },
            width: "15%",
          },
          {
            key: "2",
            title: "ทีม 1",
            dataIndex: "teamOne",
            slots: { customRender: "teamOne" },
            width: "35%",
          },
          {
            key: "3",
            title: "ผลการแข่งขัน",
            dataIndex: "matchResult",
            align: "center",
            slots: { customRender: "matchResult" },
            width: "15%",

            // customRender: ( text:any, record:any, index:any ) => {
            //   // return (<p>test</p>
            //   return text?.record?.teamOne?.score + "-" + text?.record?.teamTwo?.score;
            // },
          },
          {
            key: "4",
            title: "ทีม 2",
            dataIndex: "teamTwo",
            slots: { customRender: "teamTwo" },
            width: "35%",
          },
        ],
        en: [
          {
            key: "1",
            title: "Date of Competition",
            dataIndex: "date",
            slots: { customRender: "date" },
            width: "15%",
          },
          {
            key: "2",
            title: "Team 1",
            dataIndex: "teamOne",
            slots: { customRender: "teamOne" },
            width: "35%",
          },
          {
            key: "3",
            title: "Result",
            dataIndex: "matchResult",
            align: "center",
            slots: { customRender: "matchResult" },
            width: "15%",

            // customRender: ( text:any, record:any, index:any ) => {
            //   // return (<p>test</p>
            //   return text?.record?.teamOne?.score + "-" + text?.record?.teamTwo?.score;
            // },
          },
          {
            key: "4",
            title: "Team 2",
            dataIndex: "teamTwo",
            slots: { customRender: "teamTwo" },
            width: "35%",
          },
        ],
      },
      dataSource: [
        {
          key: "1",
          date: "14/08/2564",
          teamOne: "ทีมชาติมาเลเซีย",
          result: "3 - 2",
          teamTwo: "ทีมชาติพม่า",
        },
        {
          key: "1",
          date: "14/08/2564",
          teamOne: "ทีมชาติสิงค์โปร",
          result: "0 - 6",
          teamTwo: "ทีมชาติลาว",
        },
      ],
    });
    return {
      t,
      store,
      ...toRefs(state),
    };
  },
});
